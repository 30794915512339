import Noty from 'noty'
import { delay } from 'lodash'

const jQuery = window.jQuery || window.$ // use global jQuery with plugins

export const commonConfig = {
  delimiters: ['${', '}'],
  data: {
    veriliveStateMachine: {
      state: 'init',
    },
    notifyOptions: {
      layout: 'bottomCenter',
      theme: 'relax',
      timeout: 30 * 1000,
      progressBar: false,
      maxVisible: 1,
    },
    buttonText: 'Запустить',
    isVerigramSuccessCallback: false,
    isVerigramVideoSentCallback: false,
    isVerigramFailCallback: false,
    currentStep: 0,
    isCameraEnabled: false,
    biometryResult: null,
    isSendingVideoToVerilive: false,
    verigramApiKey: null,
    veriliveToken: null,
    veriliveConfig: {
      recordVideo: true,
      rotated: false,
      lang: 'ru',
      render: {
        oval: true,
        faceContourInsteadOfOval: true,
        ovalRingColor: {
          default: '#F5F542',
          actionSuccess: '#00F500',
          actionFailure: '#F50000',
          sessionSuccess: '#00F500',
          sessionFailure: '#F50000',
        },
        ovalWidth: 1.0,
        overlay: true,
        overlayColor: {
          default: '#2F4F4F',
        },
        overlayTransparency: {
          default: 0.55,
        },
        arrow: true,
        arrowColor: {
          default: '#F0F0F0',
        },
        arrowProgressColor: {
          default: '#404040',
        },
        hint: true,
        hintTextColor: {
          default: '#C8C9CC',
        },
        hintFontType: 'Arial',
        hintUseProgressiveFontSize: true,
        hintProgressiveFontSizeMultiplier: 1.0,
        hintFontSize: 25,
        hintCloudColor: {
          default: '#2D312F',
        },
        videoUploadProgressBar: true,
        videoUploadProgressBarColor1: '#FFEA82',
        videoUploadProgressBarColor2: '#eee',
      },
      hints: {
        // Hints
        noHint: '',
        noFace: 'Вас не видно',
        badLight: 'Выровняйте освещение',
        closer: 'Ближе',
        away: 'Отдалитесь',
        closerToCenter: 'Ближе к центру экрана',
        targetLeft: 'Медленно поворачивайте голову влево',
        targetRight: 'Медленно поворачивайте голову вправо',
        targetCenter: 'Посмотрите прямо',
        sessionSuccess: 'Вы прошли!',
        sessionFailure: 'Вы не прошли!',
        sessionError: 'Произошла какая-то ошибка. Попробуйте перезапустить',
        // Errors
        NotSupportedBrowserError: "Ваш браузер не поддерживается. Пожалуйста, используйте последние браузера Chrome, Firefox, Safari или Edge.",
        NoWrapperError: "Что-то не так, попробуйте позже",
        CameraNotFoundError: "Веб-камера не найдена. Пожалуйста, подсоедините веб-камеру к устройству и обновите эту страничку.",
        CameraNotAllowedError: "Отказано в доступе к веб-камере. Пожалуйста, обновите эту страничку и разрешите доступ к веб-камере.",
        CameraOverconstrainedError: "Веб-камера с минимальным разрешением 480p не найдена. Пожалуйста, подсоедините веб-камеру 480p (или выше) и обновите эту страничку.",
        CameraSecurityError: "Ваш браузер отказал в доступе к веб-камере. Пожалуйста, измените настройки доступа к веб-камере в Вашем браузере.",
        CameraNotReadableError: "Ошибка веб-камеры - невозможно прочитать данные с веб-камеры. Пожалуйста, проверьте Вашу веб-камеру.",
        CameraAbortError: "Ошибка веб-камеры - невозможно прочитать данные с веб-камеры. Пожалуйста, проверьте Вашу веб-камеру.",
        CameraBrowserAppNeedsConstantCameraPermission: "Скорее всего вашему браузеру нужно больше прав на камеру. Пожалуйста используйте последние браузера Chrome, Firefox, Safari или следуйте инструкции чтобы дать больше прав https://s3.eu-central-1.amazonaws.com/verilive-statics.verigram.ai/android_camera_permission_instruction.pdf",
        CameraVirtualSuspected: "Что-то странное с вашей камерой.",
        CameraStreamInterrupted: "Работа камеры прервалась.",
        CameraStreamInterruptedPrimary: "Ошибка",
        CameraStreamInterruptedDetailed: "Работа камеры прервалась",
        SlowInternetError: "Плохое соединение. Попробуйте подключиться к более быстрому интернету",
        SlowInternetErrorPrimary: "Ошибка",
        SlowInternetErrorDetailed: "Плохое соединение. Попробуйте подключиться\nк более быстрому интернету",
        ServerWorkError: "Что-то не так с сервером, попробуйте еще раз",
        ServerWorkErrorPrimary: "Ошибка",
        ServerWorkErrorDetailed: "Проблема с сервером, попробуйте еще раз",
        ServerAuthorizationError: "Что-то не так, попробуйте позже",
        ServerAuthorizationErrorPrimary: "Ошибка",
        ServerAuthorizationErrorDetailed: "Сервис не авторизован",
        ServerConnectionError: "Сервер не доступен. Проверьте интернет, попробуйте поменять сеть, выключить VPN",
        ServerConnectionErrorPrimary: "Ошибка",
        ServerConnectionErrorDetailed: "Сервер не доступен. Проверьте интернет,\n попробуйте поменять сеть, выключить VPN",
        ClientWorkError: "Что-то не так, попробуйте еще раз",
        ClientWorkErrorPrimary: "Ошибка",
        ClientWorkErrorDetailed: "Ошибка на клиенте, попробуйте еще раз"
      },
    },
    failTimeoutId: null,
    debounceId: null,
    videoSentCallbackTimer: null,
    alternativeVideoSended: false,
    handleVeriliveCallbackErrors: {
      errorCallback: {},
    },
  },
  methods: {
    skipBiometryProcess: async function (callbackName) {
      try {
        this.biometryResult = 'fail'
        // попытка отправить данные в очередь, с интервалом в 1 секунду между попытками
        // необходимо на случай, если имеются проблемы с интернетом
        await this.debounce(1)
        await this.sendDataToQueue({ alternativeVideo: true, callbackName })
        // принудительно сообщаем системе, что пользователь не прошел биометрию
        this.failCallback()

        // в рамках коллбэка failCallback уменьшаем количество попыток
        try {
          const {
            data: { attemptsLeft },
          } = await this.decreasePersonAttempts()
          this.personInfo['attemptsLeft'] = attemptsLeft
          window.localStorage.setItem('personAttemptsLeft', attemptsLeft)
        } catch (error) {
          // данные в базу не записали, но попытки уменьшим
          if (this.personInfo['attemptsLeft'] > 0) {
            this.personInfo['attemptsLeft'] =
              this.personInfo['attemptsLeft'] - 1
          }
        }
        // если кол-во попыток не осталось, то переводим на следующий этап
        // биометрия автоматом не пройдена и он передаётся на ручное рассмотрение
        if (Number(this.personAttemptsLeft) === 0) {
          this.processPersonAttemptsLimit()
        } else {
          this.changeButtonTextAndRestartService('Повторить')
          this.scrollToStartButton()
          new Noty({
            ...this.notifyOptions,
            type: 'error',
            timeout: 15 * 1000,
            text: 'Произошла ошибка сервиса верификации, повторите попытку',
          }).show()
        }
      } catch (err) {
        this.biometryResult = null
        await this.skipBiometryProcess(callbackName)
      }
    },
    setCallbackAndInit: async function () {
      verilive.successCallback = this.successCallback
      verilive.failCallback = this.failCallback
      verilive.errorCallback = this.errorCallback
      verilive.updateCallback = this.updateCallback
      verilive.videoSentCallback = this.videoSentCallback
      verilive.videoRecordingNotSupportedCallback = this.videoRecordingNotSupportedCallback
      verilive.videoReadyCallback = this.videoReadyCallback
      verilive.videoSendProgressCallback = this.videoSendProgressCallback
      verilive.videoSendErrorCallback = this.videoSendErrorCallback
      verilive.waitScreenStartedCallback = this.waitScreenStartedCallback

      // создаем конфиг для инициации биометрии
      const veriliveDefaultConfig = verilive.getDefaultConfig()
      const config = {
        ...veriliveDefaultConfig,
        rotated: false,
        recordVideo: true,
        lang: 'ru',
        videoBitrate: this.veriliveConfig.videoBitrate,
        render: {
          ...veriliveDefaultConfig.render,
          overlayColor: {
            default: "#232333"
          },
          overlayTransparency: {
            default: 0.15
          },
          outerOverlayColor: {
            default: "#adb5bd"
          },
          outerOverlayTransparency: {
            default: 1
          },
          loadingScreenColor: {
            default: "#F0F0F0"
          },
          ovalRingColor: {
            default: "#F8AB37",
            actionSuccess: "#7DC587",
            actionFailure: "#FF3F3F",
            sessionSuccess: "#7DC587",
            sessionFailure: "#FF3F3F"
          },
        }
      }

      await verilive.init(
        this.veriliveUrl,
        config
      )
    },
    scrollToStartButton: function () {
      const veriliveBox = document.querySelector(
        '#verigram_biometry_process_start'
      )
      veriliveBox && veriliveBox.scrollIntoView({ block: 'center', behavior: 'smooth' })
      /**
       * Длинная запись на случай если браузер еще не поддерживает новый синтаксис
       * verilive?.overlay?.setLoadingVisibility?.()
       * verilive?.overlay?.cleanCanvas?.()
       */
      verilive &&
        verilive.overlay &&
        verilive.overlay.setLoadingVisibility &&
        verilive.overlay.setLoadingVisibility(!1)
      verilive &&
        verilive.overlay &&
        verilive.overlay.cleanCanvas &&
        verilive.overlay.cleanCanvas()
    },
    pushNotification: function (text, type = 'error') {
      new Noty({
        ...this.notifyOptions,
        timeout: 15 * 1000,
        type,
        text,
      }).show()
    },
    errorTypes: function (errorType) {
      const errorTypes = [
        'NotSupportedBrowserError',
        'NoWrapperError',
        'CameraNotFoundError',
        'CameraNotAllowedError',
        'CameraOverconstrainedError',
        'CameraSecurityError',
        'CameraNotReadableError',
        'CameraAbortError',
        'CameraBrowserAppNeedsConstantCameraPermission',
        'CameraVirtualSuspected',
        'CameraStreamInterrupted',
        'CameraStreamInterruptedPrimary',
        'CameraStreamInterruptedDetailed',
        'SlowInternetError',
        'SlowInternetErrorPrimary',
        'SlowInternetErrorDetailed',
        'ServerWorkError',
        'ServerWorkErrorPrimary',
        'ServerWorkErrorDetailed',
        'ServerAuthorizationError',
        'ServerAuthorizationErrorPrimary',
        'ServerAuthorizationErrorDetailed',
        'ServerConnectionError',
        'ServerConnectionErrorPrimary',
        'ServerConnectionErrorDetailed',
        'ClientWorkError',
        'ClientWorkErrorPrimary',
        'ClientWorkErrorDetailed'
      ]
      return errorTypes.includes(errorType)
    },
    changeButtonTextAndRestartService: function (text) {
      /**
       * Если ответ от сервиса verigram пришел с ошибкой,
       * но пользователь хочет испытать удачу, то обнуляем таймаут
       */
      this.clearFailTimeoutId()
      this.buttonText = text
      this.restartService()
    },
    restartService: function () {
      this.veriliveStateMachine.state = 'init'
      this.isVerigramSuccessCallback = false
      this.isVerigramVideoSentCallback = false
      this.veriliveToken = null
      this.alternativeVideoSended = false
    },
    decreasePersonAttempts: function () {
      return new Promise((resolve, reject) => {
        if (!this.tmpId) this.tmpId = jQuery('#tmp_id').val()

        fetch(this.biometryDecreaseAttemptsUrl, {
          method: 'post',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: `tmp_id=${this.tmpId}`,
        })
          .then((response) => response.json())
          .then((response) => {
            resolve(response)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearFailTimeoutId: function () {
      if (this.failTimeoutId) clearTimeout(this.failTimeoutId)
    },
    debounce: function (delay) {
      if (this.debounceId) clearTimeout(this.debounceId)
      return new Promise((resolve) => {
        this.debounceId = window.setTimeout(() => resolve(true), delay * 1000)
      })
    },
    checkPersonAttempts: function () {
      return new Promise((resolve, reject) => {
        if (!this.tmpId) this.tmpId = jQuery('#tmp_id').val()

        fetch(this.hasPersonAttemptsUrl, {
          method: 'post',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: `tmp_id=${this.tmpId}`,
        })
          .then((response) => response.json())
          .then((response) => {
            resolve(response)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    onStartButtonClick: async function () {
      /* Процесс еще не запущен */
      if (this.veriliveStateMachine.state !== 'init') return

      /* Библиотека еще не загрузилась, а пользователь жмет приступить */
      if (typeof verilive === 'undefined')
        return new Noty({
          ...this.notifyOptions,
          timeout: 10 * 1000,
          type: 'information',
          text: 'Медленное интернет соединение, пожалуйста подождите',
        }).show()

      /**
       * Returns true, if camera is initialized and active. Otherwise, return false
       * Call before start() to make check
       */
      if (!verilive.isCameraInitialized()) {
        return new Noty({
          ...this.notifyOptions,
          timeout: 15 * 1000,
          type: 'information',
          text: 'Произошла ошибка, нет доступа к камере, или она не активна',
        }).show()
      }

      /**
       * Returns true, if this browser/device supports video recording. Otherwise, returns false.
       */
      if (!verilive.isVideoRecordingSupported()) {
        return this.videoRecordingIsUnSupported()
      }

      try {
        const { hasPersonAttempts } = await this.checkPersonAttempts()
        // закончились попытки
        if (!hasPersonAttempts) {
          this.veriliveStateMachine.state = 'successCallback'
          this.biometryResult = 'success'
          this.isVerigramSuccessCallback = true
          this.isVerigramVideoSentCallback = true
          this.personInfo['attemptsLeft'] = 0
          return
        }
      } catch (error) {
        return new Noty({
          ...this.notifyOptions,
          timeout: 15 * 1000,
          type: 'error',
          text: error?.message || 'Произошла ошибка, повторите попытку',
        }).show()
      }

      try {
        this.veriliveStateMachine.state = 'process'
        this.isVerigramSuccessCallback = false
        this.isVerigramVideoSentCallback = false
        /* Проверки прошли успешно, можно приступать */
        const { data: { access_token: accessToken } } = await this.getAccessToken();
        this.veriliveToken = await verilive.start(accessToken, this.tmpId)
        // отправляем в метрику значение session_id
        if (typeof window.Ya !== 'undefined') ym(34471555, 'params', { session_id: this.veriliveToken })
        if (typeof window.ym !== 'undefined') ym(34471555, 'params', { session_id: this.veriliveToken })

        // const veriliveBox = document.querySelector('#id_verilive')
        // veriliveBox.scrollIntoView({ block: 'start', behavior: 'smooth' })
      } catch (err) {
        this.handleVeriliveCallbackErrors.errorCallback[this.veriliveToken] =
          err || new Error('Произошла ошибка, повторите попытку')
        this.changeButtonTextAndRestartService('Повторить')
        this.scrollToStartButton()
        new Noty({
          ...this.notifyOptions,
          timeout: 15 * 1000,
          type: 'error',
          text: err?.message || 'Произошла ошибка, повторите попытку',
        }).show()
      }
    },
    /**
     * Check whether device has one or more cameras
     * If returns undefined - than browser has no MediaDevices API implemented
     * @returns true | false
     */
    hasCamera: async function () {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices()
        return devices.some(({ kind }) => kind === 'videoinput')
      } catch (err) {
        return false
      }
    },
    getApiKeyAndInitVerigram: async function () {
      try {
        if (typeof verilive === 'undefined') return
        const { count = 0, data = {} } = await this.getApiKey()
        // не удалось получить ключ
        if (!count) return
        this.verigramApiKey = data['verigram.apiKey']
        await this.initVerilive()
      } catch (err) {
        console.error(err)
        this.failTimeout({ callbackName: 'getApiKeyAndInitVerigram' })
      }
    },
    getAccessToken: function () {
      if (!this.tmpId) this.tmpId = jQuery('#tmp_id').val()
      return new Promise((resolve, reject) => {
        fetch('/app.php/settings/getAccessToken', {
          method: 'post',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: 'action=getAccessToken&personTmpId=' + this.tmpId,
        })
          .then((response) => response.json())
          .then((result) => {
            try {
              const parsed = JSON.parse(result)
              resolve(parsed)
            } catch (err) {
              reject(err)
            }
          })
          .catch((err) => reject(err))
      })
    },
    getApiKey: function () {
      return new Promise((resolve, reject) => {
        fetch('/app.php/settings/getSettings', {
          method: 'post',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: 'action=getSettings&settings[]=verigram.apiKey',
        })
          .then((response) => response.json())
          .then((result) => {
            try {
              const parsed = JSON.parse(result)
              resolve(parsed)
            } catch (err) {
              reject(err)
            }
          })
          .catch((err) => reject(err))
      })
    },
    /**
     * Устройство не поддерживает необходимый формат записи видео
     */
    videoRecordingIsUnSupported: async function () {
      await verilive.dispose()
      Noty.closeAll()
      return new Noty({
        ...this.notifyOptions,
        timeout: 15 * 1000,
        type: 'information',
        text: 'Ваше устройство не поддерживает необходимый формат записи видео. Попробуйте на другом устройстве.',
      }).show()
    },
    /**
     * This callback will be called if the user passed liveness with results (in JSON format). Image for
     * recognition is available under the json variable “bestFrame”
     * @param _result
     */
    successCallback: async function (_result) {
      this.veriliveStateMachine.state = 'successCallback'
      /**
       * Успешное прохождение биометрии
       */
      this.clearFailTimeoutId()

      this.biometryResult = 'success'
      this.isVerigramSuccessCallback = true
      this.videoSentCallbackTimer = delay(
        this.videoSentCallback,
        this.videoSentCallbackDelay * 1000,
        this.veriliveToken,
        true
      )
    },
    /**
     * This callback will be called if the user failed liveness with results (in JSON format).
     * @param _result
     */
    failCallback: function (_result) {
      this.veriliveStateMachine.state = 'failCallback'
      /**
       * Биометрия завалена
       */
      this.clearFailTimeoutId()

      this.biometryResult = 'fail'
      this.isVerigramFailCallback = true
      this.videoSentCallbackTimer = delay(
        this.videoSentCallback,
        this.videoSentCallbackDelay * 1000,
        this.veriliveToken,
        true
      )
    },
    /**
     * При возникновении технической ошибки отображать
     * сообщение об ошибке "Произошла ошибка, повторите попытку"
     * кнопку "Повторить", которая снова запускает процесс биометрической идентификации.
     */
    errorCallback: function (err = null) {
      /** Отлов ошибок библиотеки verilive */
      let errorMessage = 'Произошла неизвестная ошибка, повторите попытку!'
      this.buttonText = 'Повторить'
      Noty.closeAll()
      if (typeof err === 'object' && err.name && this.errorTypes(err.name)) {
        errorMessage = err.message
      }
      this.pushNotification(errorMessage)
      this.scrollToStartButton()
      const errorCallbackData = {
        [this.veriliveToken]:
          err || new Error('Произошла неизвестная ошибка, повторите попытку!'),
      }

      this.handleVeriliveCallbackErrors.errorCallback = {
        ...this.handleVeriliveCallbackErrors.errorCallback,
        ...errorCallbackData,
      }
      this.veriliveStateMachine.state = 'errorCallback'
    },
    /**
     * This callback will be called when verilive.js provides some intermediate information.
     * @param event
     */
    updateCallback: function (event) { },
    /**
     * This callback will be called on start if video recording is on
     * and this browser/device does not support it.
     */
    videoRecordingNotSupportedCallback: function () {
      this.buttonText = 'Повторить'
      this.veriliveStateMachine.state = 'init'
      Noty.closeAll()
      this.pushNotification(
        'Ваш браузер или устройство не поддерживается. Пожалуйста, попробуйте повторить попытку на другом устройстве или используйте последние версии браузера Chrome, Firefox, Safari или Edge.'
      )
      this.scrollToStartButton()
    },
    /**
     * This callback will be called when video has been recorded
     * and is ready but has not been sent to server yet.
     * Called after successCallback/errorCallback
     * @param blob
     * @param sessionId
     */
    videoReadyCallback: function (blob, sessionId) {
      if (this.veriliveToken === sessionId) {
        this.isSendingVideoToVerilive = true
        if (!this.handleVeriliveCallbackErrors.errorCallback[sessionId]) {
          Noty.closeAll()
          new Noty({
            ...this.notifyOptions,
            timeout: false,
            type: 'information',
            text: 'Дождитесь окончания загрузки видео',
          }).show()
        }
      }
    },
    /**
     * This callback will be when there is progress on upload of video to server.
     * @param event Object containing progress data. Is same as event argument in
     * XMLHttpRequest.upload.onprogress(event).
     * event.loaded - number of bytes already loaded, event.total - total number of bytes to
     * upload.
     * @param sessionId
     */
    videoSendProgressCallback: function (event, sessionId) { },
    waitScreenStartedCallback: function () {},
    /**
     * This callback will be when there is error uploading video.
     * @param sessionId
     */
    videoSendErrorCallback: function (sessionId) {
      /** Если есть значение, значит мы отловили ошибку интернет соединения
       * и второй раз отправлять отправлять данные по этому коллбеку не нужно
       */
      if (sessionId === this.veriliveToken) {
        const errorMessage = this.veriliveConfig.hints.ServerConnectionError
        this.buttonText = 'Повторить'
        this.restartService()
        Noty.closeAll()
        this.pushNotification(errorMessage)
        this.scrollToStartButton()
      }
    },
    /**
     * This callback will be when video has been sent from frontend to server.
     * This happens after successCallback/errorCallback and after videoReadyCallback
     * @param sessionId
     * @returns {Promise<void>}
     */
    videoSentCallback: async function (sessionId, alternativeVideo = false) {
      // удаляем таймер чтобы не запустился повторно videoSentCallback по таймеру
      this.videoSentCallbackTimer && clearTimeout(this.videoSentCallbackTimer)
      // если отправили однажды, то пока не перезапустим сервис, видео по колбеку не отправится
      if (this.alternativeVideoSended) return

      this.handleVeriliveCallbackErrors.errorCallback[sessionId] = null // обнуляем ошибку, видео отправили успешно
      if (sessionId === this.veriliveToken) {
        Noty.closeAll()
        // очистка таймера вызванного в videoSendProgressCallback
        this.clearFailTimeoutId()
        /**
         * Идеальные условия, прошли биометрию и видео загрузили
         */
        if (
          this.veriliveStateMachine.state === 'failCallback' ||
          this.veriliveStateMachine.state === 'successCallback'
        ) {
          try {
            // если успешно прошел биометрию, но видео долго отправляется на сервер
            // используем альтернативное видео, и игнорируем calbback
            this.alternativeVideoSended = alternativeVideo

            await this.sendDataToQueue({ alternativeVideo, callbackName: 'videoSentCallback' })
            // Пока видео не было успешно загружено на сервер, не можем уменьшить кол-во попыток
            // в рамках коллбэка failCallback
            try {
              const {
                data: { attemptsLeft },
              } = await this.decreasePersonAttempts()
              this.personInfo['attemptsLeft'] = attemptsLeft
              window.localStorage.setItem('personAttemptsLeft', attemptsLeft)
            } catch (error) {
              // данные в базу не записали, но попытки уменьшим
              if (this.personInfo['attemptsLeft'] > 0) {
                this.personInfo['attemptsLeft'] =
                  this.personInfo['attemptsLeft'] - 1
              }
            }
            // Закончились попытки или удачная биометрия
            if (
              Number(this.personAttemptsLeft) === 0 ||
              this.isVerigramSuccessCallback
            ) {
              this.processVideoSentPersonAttemptsLimit()
            } else {
              // неудачная попытка прохождения биометрии, но попытки еще остались
              if (this.veriliveStateMachine.state === 'failCallback') {
                const messageToUser = {
                  type: 'error',
                  text: 'Вы не прошли идентификацию. Повторите попытку',
                }
                Noty.closeAll()
                new Noty({
                  ...this.notifyOptions,
                  ...messageToUser,
                }).show()
                // неудачная попытка, перезапускаем
                this.restartService()
              }
            }
            this.scrollToStartButton()
          } catch (err) {
            // отловили ошибку отправки данных в очередь
            const errorMessage = this.veriliveConfig.hints.ServerConnectionError
            const messageToUser = {
              type: 'error',
              text: errorMessage,
            }
            Noty.closeAll()
            new Noty({
              ...this.notifyOptions,
              ...messageToUser,
            }).show()
            this.restartService()
          }
        } else {
          // что-то пошло не так, перезапускаем
          this.veriliveStateMachine.state !== 'finished' && this.restartService()
        }
      }
    },
    createQueueBody: function ({ alternativeVideo, callbackName }) {
      try {
        if (!this.tmpId) this.tmpId = jQuery('#tmp_id').val()
        let initialBodyString = `session_id=${this.veriliveToken}&tmp_id=${this.tmpId}&result=${this.biometryResult}&alternativeVideo=${alternativeVideo}&callbackName=${callbackName}`
        // генерация данных если у пользователя в ЛК есть камера на устройстве
        if (typeof this.getCurrentLoan === 'function') {
          const loan = this.getCurrentLoan()

          if (loan && loan.id) {
            initialBodyString = initialBodyString + `&creditId=${loan.id}`
          }

          if (loan && loan.prolongation && loan.prolongation.prolongationId) {
            initialBodyString = initialBodyString + `&prolongationId=${loan.prolongation.prolongationId}`
          }
        }
        // генерация данных со спец страницы
        if (typeof this.getCreditAndProlongationId === 'function') {
          const { creditId, prolongationId } = this.getCreditAndProlongationId()

          if (creditId) {
            initialBodyString = initialBodyString + `&creditId=${creditId}`
          }

          if (prolongationId) {
            initialBodyString = initialBodyString + `&prolongationId=${prolongationId}`
          }
        }
        return initialBodyString
      } catch (error) {
        console.log(error);
      }
    },
    sendDataToQueue: function (params) {
      return new Promise((resolve, reject) => {

        const body = this.createQueueBody(params)

        fetch(this.biometryResultUrl, {
          method: 'post',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body
        })
          .then((response) => response.json())
          .then((response) => {
            resolve(response)
          })
          .catch((err) => reject(err))
      })
    },
    failTimeout: function ({ delay = null, callbackName }) {
      this.clearFailTimeoutId()
      const delayTimeout = delay ? delay : this.biometryTimeout * 1000
      this.failTimeoutId = window.setTimeout(
        () => this.skipBiometryProcess(callbackName),
        Number(delayTimeout)
      )
    },
  },
  computed: {
    personAttempts: function () {
      return this.personInfo['attempts']
    },
    personAttemptsLeft: function () {
      return this.personInfo['attemptsLeft']
    },
    personStatus: function () {
      return this.personInfo['status']
    },
    baseButtonClass: function () {
      return {
        'accept-and-continue': true,
        'verigram-big-button': true,
        'verigram-big-button-green': true,
      }
    },
    biometryButtonClass: function () {
      return {
        ...this.baseButtonClass,
        'accept-and-continue__disabled':
          this.veriliveStateMachine.state !== 'init',
      }
    },
  },
}
