import { addScript } from '../../modules/site'
import { commonConfig } from '../../config/biometryConfig'
import Noty from 'noty'

const jQuery = window.jQuery || window.$ // use global jQuery with plugins

function getValue(val, el) {
  if (typeof val === 'undefined') {
    return jQuery(el).data('value')
  }
  return val
}

export function init(data = {}) {
  const {
    checkCodeButtonTemplate,
    personInfoParam,
    mainDomain,
    codeButtonForViewParam,
    verigramVideoBitrateParam,
    biometryTimeoutParam,
    verigramLibraryPathParam,
    verigramServerUrlParam,
    videoSentCallbackDelayParam,
    getCurrentLoan = () => {}
  } = data
  const check_code_button_template = getValue(
    checkCodeButtonTemplate,
    '#__check_code_button_template'
  )
  const personInfo = getValue(personInfoParam, '#__personInfo')
  const main_domain = getValue(mainDomain, '#__main_domain')
  const codeButtonForView = getValue(
    codeButtonForViewParam,
    '#__codeButtonForView'
  )
  const biometryTimeout = getValue(biometryTimeoutParam, '#__biometryTimeout')
  const verigramVideoBitrate = getValue(
    verigramVideoBitrateParam,
    '#__verigramVideoBitrate'
  )
  const verigramLibraryPath = getValue(
    verigramLibraryPathParam,
    '#__verigramLibraryPath'
  )

  const verigramServerUrl = getValue(
    verigramServerUrlParam,
    '#__verigramServerUrl'
  )

  const videoSentCallbackDelay = getValue(
    videoSentCallbackDelayParam,
    '#__videoSentCallbackDelay'
  )

  return new Vue({
    ...commonConfig,
    el: '#app-verigram-biometry',
    data: {
      ...commonConfig.data,
      videoSentCallbackDelay: Number(videoSentCallbackDelay),
      biometryTimeout: biometryTimeout,
      codeButtonForView: codeButtonForView,
      checkCodeButtonTemplateStyle: check_code_button_template,
      isShowVerigramForm: false,
      clickToSaveAndContinueButton: false,
      personInfo: personInfo,
      fullUrl: '/app.php/biometry/form',
      tmpId: null,
      biometryResultUrl: `${main_domain}app.php/send-biometry-result`,
      biometryDecreaseAttemptsUrl: `${main_domain}app.php/biometry/decreasePersonAttempts`,
      hasPersonAttemptsUrl: `${main_domain}app.php/biometry/hasPersonAttempts`,
      getStatusInfoByTmpIdUrl: `${main_domain}app.php/settings/getStatusInfoByTmpId`,
      getStatusInfoUrl: `${main_domain}app.php/settings/getStatusInfo`,
      veriliveUrl: verigramServerUrl,
      veriliveConfig: {
        ...commonConfig.data.veriliveConfig,
        videoBitrate: Number(verigramVideoBitrate),
      },
      showMobileTooltip: false,
      tooltipText:
        'Под биометрической идентификацией понимается проведение идентификации в соответствие с требованиями законодательства Республики Казахстан',
      isDisableRefs: false,
    },
    methods: {
      ...commonConfig.methods,
      getCurrentLoan: function () {
        return getCurrentLoan()
      },
      processPersonAttemptsLimit: function () {
        this.isVerigramVideoSentCallback = true
      },
      processVideoSentPersonAttemptsLimit: function () {
        this.isVerigramVideoSentCallback = true
      },
      showVerigramForm: function () {
        const app =
          this.codeButtonForView === 'desktop'
            ? null
            : jQuery('#app-verigram-biometry')
        if (app) {
          this.showMobileTooltip = true
          // mobile версия
          const appWrapper = app.siblings('#app-verigram-biometry-subling')
          appWrapper
            .text('Биометрическая идентификация')
            .css('font-size', '19px')
        } else {
          const stageName = jQuery('#stage-2').prev('.stage-name')
          stageName.find('a').text('2. Биометрическая идентификация')
          stageName.append(`
                        <span
                            style="width: 16px;height: 16px;"
                            class="help-popup"
                            data-toggle="tooltip"
                            data-placement="right" title=""
                            data-original-title="${this.tooltipText}">&nbsp;
                        </span>`)
          jQuery('.help-popup').tooltip()
        }

        this.isShowVerigramForm = true
      },
      handleCheckCodeButton: function () {
        const wrapperEl =
          this.codeButtonForView === 'desktop'
            ? '.control-group'
            : '.mobile-control-group'
        const url = '/register/'
        const emailCode = jQuery('#email_code')
        const emailCodeValue = emailCode.val()
        const tmpId = jQuery('#tmp_id').val()
        const wrapper = emailCode.closest(wrapperEl)
        const helpLine = wrapper.find('.help-inline')

        const data = {
          tmp_id: tmpId,
          check_code: 1,
          email_code: emailCodeValue,
        }

        jQuery
          .ajax({
            type: 'POST',
            url: url,
            data: data,
          })
          .done((result) => {
            const parsed = JSON.parse(result)
            const { error = 0, error_txt = '', ok = 0 } = parsed
            helpLine.html(error_txt)
            if (error === 1) {
              wrapper.removeClass('green')
              wrapper.addClass('error')
            } else {
              wrapper.removeClass('error')
              wrapper.addClass('green')
              this.showVerigramForm()
            }
          })
      },
      buildUrlString: function (param) {
        return (this.fullUrl =
          'https://chart.googleapis.com/chart?cht=qr&chs=' +
          200 +
          'x' +
          200 +
          '&chl=' +
          encodeURIComponent(param))
      },
      buildPersonalUrl: function ({ tmpId, creditId = null, prolongationId = null }) {
        let param = main_domain + 'app.php/biometry/personal_form?tmp_id=' + tmpId
        if (creditId) param = param + '&creditId=' + creditId
        if (prolongationId) param = param + '&prolongationId=' + prolongationId
        return this.buildUrlString(param)
      },
      buildUrl: function ({ tmpId }) {
        const param = main_domain + 'app.php/biometry/form?tmp_id=' + tmpId
        return this.buildUrlString(param)
      },
      validateBiometryForm: function () {
        Noty.closeAll()
        return fetch(this.getStatusInfoByTmpIdUrl, {
          method: 'post',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: `tmp_id=${this.tmpId}`,
        })
          .then((response) => response.json())
          .then((response) => {
            const data = JSON.parse(response)
            /**
             * если status = passed или attemptsLeft = 0, то отправлять данные формы 2 шага (чтобы происходил переход на 3ий шаг)
             * в противном случае отображать сообщение об ошибке
             */
            if (
              (data && data.status && data.status === 'success') ||
              (data && Number(data.attemptsLeft) === 0)
            ) {
              this.clickToSaveAndContinueButton = true
            } else {
              new Noty({
                ...this.notifyOptions,
                type: 'information',
                text: 'Ожидание результата биометрической идентификации.',
              }).show()
            }
          })
      },
      prevBiometryForm: function () {
        Noty.closeAll()
        this.currentStep = 0
      },
      nextProcess: async function () {
        /**
         * Установка нового шага для прохождения verigram идентификации
         * Служит для отображения информационного текста и согласия с ним и перехода на следующий шаг
         * Возможные состояния 0 | 1
         */
        this.currentStep = 1
        /**
         * Если имеется камера, то инициируем verigram
         */
        if (this.showBiometryWithCameraState) {
          await this.getApiKeyAndInitVerigram()
        }
      },
      initVerilive: async function () {
        try {
          if (typeof verilive === 'undefined') {
            await addScript(verigramLibraryPath)
          }
          await this.setCallbackAndInit()
        } catch (e) {
          this.errorCallback(e)
        }
      },
    },
    created: async function () {
      const tmpId = jQuery('#tmp_id').val()
      this.tmpId = tmpId
      this.isCameraEnabled = await this.hasCamera()
      if (!this.isCameraEnabled) this.buildUrl({ tmpId })
    },
    computed: {
      ...commonConfig.computed,
      verigramContainerStyle: function () {
        if (
          this.showBiometryWithCameraState &&
          this.veriliveStateMachine.state === 'init' &&
          this.currentStep === 1
        ) {
          return { height: '32rem' }
        }
        return {}
      },
      verigramMobileContainerStyle: function () {
        if (
          this.showBiometryWithCameraState &&
          this.veriliveStateMachine.state === 'init' &&
          this.currentStep === 1
        ) {
          return { height: '18rem' }
        }
        return {}
      },
      successProcess: function () {
        if (this.isVerigramVideoSentCallback) {
          if (
            Number(this.personAttemptsLeft) === 0 ||
            this.isVerigramSuccessCallback
          ) {
            /* Отключаем камеру */
            verilive.dispose()
            /* Запускает процесс клика по кнопке */
            this.clickToSaveAndContinueButton = true
            return true
          }
        }
        return false
      },
      /**
       * Является ли первоначальным состоянием
       */
      showInitState: function () {
        return this.currentStep === 0
      },
      /**
       * Отображать ли верстку с биометрией через verigram
       */
      showBiometryWithCameraState: function () {
        if (this.currentStep === 1) {
          return this.isCameraEnabled
        }
        return false
      },
      /**
       * Отображать ли верстку с QR кодом
       */
      showBiometryWithoutCameraState: function () {
        if (this.currentStep === 1) {
          return !this.isCameraEnabled
        }
        return false
      },
    },
    watch: {
      showBiometryWithoutCameraState: function (val, _oldVal) {
        if (val) {
          const tmpId = jQuery('#tmp_id').val()
          this.tmpId = tmpId
          this.buildUrl({ tmpId })
        }
      },
      clickToSaveAndContinueButton: function (val, oldVal) {
        if (val !== oldVal && val && !this.isDisableRefs) {
          this.veriliveStateMachine.state = 'finished'
          this.veriliveToken = null
          this.$refs.saveAndContinueRef.click()
          this.$refs.saveAndContinueCameraStateRef.click()
        }
      },
    },
    beforeDestroy: function () {
      this.clearFailTimeoutId()
      if (this.debounceId) clearTimeout(this.debounceId)
    },
  })
}
